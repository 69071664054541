module.exports = [{
      plugin: require('../node_modules/gatsby-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/opt/build/repo/src/templates/markdown-page.js"},"extensions":[".mdx",".md",".markdown"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-copy-linked-files"},{"resolve":"gatsby-remark-images","options":{"backgroundColor":"#fafafa","maxWidth":1035,"sizeByPixelDensity":true}}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Ayomide Bakare","short_name":"ayomidebakare","description":"Hey! Learn from my experiences in building a career in technology","start_url":"/","background_color":"#388ce6","theme_color":"#388ce6","display":"standalone","icons":[{"src":"/favicons/android-icon-36x36.png","sizes":"36x36","type":"image/png","density":"0.75"},{"src":"/favicons/android-icon-48x48.png","sizes":"48x48","type":"image/png","density":"1.0"},{"src":"/favicons/android-icon-72x72.png","sizes":"72x72","type":"image/png","density":"1.5"},{"src":"/favicons/android-icon-96x96.png","sizes":"96x96","type":"image/png","density":"2.0"},{"src":"/favicons/android-icon-144x144.png","sizes":"144x144","type":"image/png","density":"3.0"},{"src":"/favicons/android-icon-192x192.png","sizes":"192x192","type":"image/png","density":"4.0"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-mixpanel/gatsby-browser.js'),
      options: {"plugins":[],"apiToken":"919b5f2ad85017be7b628907625d4b84","debug":false,"enableOnDevMode":false,"pageViews":"all"},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/lib/typography"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
